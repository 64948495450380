// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nie-udalo-sie-tsx": () => import("./../src/pages/nie-udało-się.tsx" /* webpackChunkName: "component---src-pages-nie-udalo-sie-tsx" */),
  "component---src-pages-poczekaj-az-skonwertujemy-twoj-plik-tsx": () => import("./../src/pages/poczekaj-aż-skonwertujemy-Twój-plik.tsx" /* webpackChunkName: "component---src-pages-poczekaj-az-skonwertujemy-twoj-plik-tsx" */),
  "component---src-pages-udalo-sie-tsx": () => import("./../src/pages/udało-się.tsx" /* webpackChunkName: "component---src-pages-udalo-sie-tsx" */),
  "component---src-pages-upusc-plik-tsx": () => import("./../src/pages/upuść-plik.tsx" /* webpackChunkName: "component---src-pages-upusc-plik-tsx" */)
}

